<template>
    <div>
        <v-snackbar v-model="snackbarProjectNotReady" :timeout="3000" top color="red" class="mt-0 pt-0">
            <span class="black--text body-2 font-weight-medium">Project not ready for public access</span>
            <v-btn text class="black--text ml-16" @click="snackbarProjectNotReady = false" icon ><v-icon>mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-row class="ma-0 pa-0 pt-10 px-6">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="ma-0 pa-0 pb-10">
                <h1 v-show="$vuetify.breakpoint.mdAndUp" class="display-3 font-weight-black" style="line-height: 1.4;">Software Engineer.<br />Perpetual Student.<br />Craftsman.</h1>
                <h1 v-show="$vuetify.breakpoint.smAndDown" class="display-1 font-weight-black" style="line-height: 1.4;">Software Engineer.<br />Perpetual Student.<br />Craftsman.</h1>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="ma-0 pa-0">
                <v-card elevation="4">
                    <v-parallax src="@/assets/background.jpg" height="300"></v-parallax>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="my-16 mx-6"></v-divider>
        <v-row justify="center" class="ma-0 pa-0 px-6 pb-10">
            <p class="display-1 text-center">Check out some of my latest projects</p>
        </v-row>
        <v-row class="ma-0 pa-0" justify="center">
           <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://www.artiusid.dev/" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">Proven Identity</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/Q5id.png" height="100" elevation="8" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Proofing solution that vets potential employees using their proven identity</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://www.artiusid.dev/" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">Guardian</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/Guardian.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">A new app revolutionizing how missing people can be found</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://loginshield.com" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">LoginShield</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/LoginShield.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Two-factor authentication app with phishing protection</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-divider class="mt-6 mb-16 mx-6"></v-divider>
        <v-row class="ma-0 pa-0 px-10 pb-10" justify="center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0">
                <v-row justify="center">
                    <v-btn dark x-large elevation="8" href="./Resume.pdf" target="_blank" height="60" width="200" style="font-size: 18px;">Resume</v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center" class="px-10 pt-10 pb-4">
            <p class="display-1 text-center">Letters of Recommendation</p>
        </v-row>
        <v-row class="ma-0 pa-0 px-10" justify="center">
            <v-col cols="12" class="ma-0 pa-0">
                <v-row justify="center">
                    <v-btn v-for="(recommendation, i) in recommendationList" :key="i" dark large elevation="6" class="ma-2" min-width="150" :href=recommendation.value target="_blank">{{ recommendation.label }}</v-btn>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {    
            snackbarProjectNotReady: false,
            recommendationList: [   
                {
                    label: 'Canyons',
                    value: 'Canyons Letter of Recommendation.pdf',
                },
                {
                    label: 'University',
                    value: 'SOU Letter of Recommendation.pdf',
                },
                {
                    label: 'Intel',
                    value: 'Intel Letter of Recommendation.pdf',
                },
                {
                    label: 'Cryptium',
                    value: 'Cryptium Letter of Recommendation.pdf',
                }, 
            ],
        };
    },

    methods: {
        projectNotReady() {
            this.snackbarProjectNotReady = true;
        }
    },
}
</script>
